import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Text, Button, Loader } from "../commonElements"
import { createSaveFile } from "../../redux/saveFile/actions"
import { openFileModal } from "../../redux/modal/actions"
import { setDataStatus, setCurrentFileInfo } from "../../redux/common/actions"
import { useAsyncDispatch } from "../../components/hook/useAsync"

const BeforeReplaceFile = ({ type }) => {
  const [error, setError] = useState("")
  const dispatch = useDispatch()
  const fileModalData = useSelector(state => state.modal.fileModalData)

  const { execute: createSaveFileAsync, loading: createSaveFileLoading } =
    useAsyncDispatch(createSaveFile)

  const replaceFileClicked = async () => {
    setError("")
    try {
      const data = {
        sharePage: fileModalData.page,
        type: type,
        fileData: {
          savePath: fileModalData.path,
        },
      }

      const result = await createSaveFileAsync(data)
      if (!result.error) {
        dispatch(
          setCurrentFileInfo({ id: fileModalData.id, path: fileModalData.path })
        )
        dispatch(setDataStatus("Unchanged"))
        dispatch(openFileModal(null))
      } else {
        setError(`Error. ${result.error}`)
      }
    } catch (error) {
      setError(
        `Error. We can't replace the file. Please try again after a few seconds. If error persists, please let us know.`
      )
      console.log("error", error)
    }
  }

  if (createSaveFileLoading) return <Loader alignSelf="center" mt="40px" />

  return (
    <Box alignItems="center">
      <Text textAlign="center">
        Are you sure you want to replace File {fileModalData.id + 1}? The
        replaced file cannot be undone!
      </Text>
      <Box alignItems="center" mt="1.6rem">
        <Button onClick={replaceFileClicked}>Yes, noted.</Button>
        {error && (
          <Text color="reject" size="1.6rem">
            {error}
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default BeforeReplaceFile
