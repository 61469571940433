import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Text, Button, Loader } from "../commonElements"
import { setCurrentFileInfo } from "../../redux/common/actions"
import { openFileModal } from "../../redux/modal/actions"
import { deleteSaveFile } from "../../redux/saveFile/actions"
import { useAsyncDispatch } from "../../components/hook/useAsync"

const BeforeDeleteFile = () => {
  const [error, setError] = useState("")
  const dispatch = useDispatch()
  const fileModalData = useSelector(state => state.modal.fileModalData)
  const currentFileInfo = useSelector(state => state.common.currentFileInfo)

  const { execute: deleteSaveFileAsync, loading: deleteSaveFileLoading } =
    useAsyncDispatch(deleteSaveFile)

  const deleteSaveFileClicked = async () => {
    setError("")
    try {
      const data = {
        sharePage: fileModalData.page,
        sharePath: fileModalData.path,
      }

      const result = await deleteSaveFileAsync(data)
      if (!result.error) {
        if (currentFileInfo && currentFileInfo.path === fileModalData.path) {
          dispatch(setCurrentFileInfo(null))
        }
        dispatch(openFileModal(null))
      } else {
        setError(`Error. ${result.error}`)
      }
    } catch (error) {
      setError(
        `Error. We can't delete the file. Please try again later. If error persists, please let us know.`
      )
      console.log("error", error)
    }
  }

  if (deleteSaveFileLoading) return <Loader alignSelf="center" mt="40px" />

  return (
    <Box alignItems="center">
      <Text textAlign="center">
        Are you sure you want to permantly delete File {fileModalData.id + 1}?
        Deleted file cannot be restored!
      </Text>
      <Box alignItems="center" mt="1.6rem">
        <Button onClick={deleteSaveFileClicked}>Yes, noted.</Button>
        {error && (
          <Text color="reject" size="1.6rem">
            {error}
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default BeforeDeleteFile
