import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Text, Button, Loader, Input } from "../commonElements"
import { createSaveFile } from "../../redux/saveFile/actions"
import styled from "styled-components"
import { setDataStatus, setCurrentFileInfo } from "../../redux/common/actions"
import { useAsyncDispatch } from "../../components/hook/useAsync"

const InputsBox = styled(Box)`
  ${props => props.disabled && `pointer-events: none;`}
`

const TextInput = styled(Input)`
  background-color: #f2f2f2;
  border: none;
  flex: 1;
  min-height: 4rem;
  border-radius: 2px;
`

const BeforeSaveFile = ({ type }) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [fileName, setFileName] = useState("")
  const dispatch = useDispatch()
  const fileModalData = useSelector(state => state.modal.fileModalData)

  const { execute: createSaveFileAsync, loading: createSaveFileLoading } =
    useAsyncDispatch(createSaveFile)

  const createSaveFileClicked = async () => {
    setError("")
    setSuccess("")
    try {
      const data = {
        sharePage: fileModalData.page,
        type: type,
        fileData: {
          saveName: fileName,
          saveId: fileModalData.id,
        },
      }

      const result = await createSaveFileAsync(data)
      if (!result.error) {
        setSuccess("Saved!")
        dispatch(
          setCurrentFileInfo({ id: fileModalData.id, path: result.path })
        )
        dispatch(setDataStatus("Unchanged"))
      } else {
        setError(`Error. ${result.error}`)
      }
    } catch (error) {
      setError(
        `Error. We can't save to the file. Please try again after a few seconds. If error persists, please let us know.`
      )
      console.log("error", error)
    }
  }

  if (createSaveFileLoading) return <Loader alignSelf="center" mt="40px" />

  return (
    <InputsBox alignItems="center" disabled={success}>
      <Box gap=".5rem" mb="1.5rem" flexDirection="row" alignItems="center">
        <Text>Name:</Text>
        <TextInput
          value={fileName}
          placeholder="Type here..."
          onChange={event => {
            event.preventDefault()
            const tempText = event.target.value
            setFileName(tempText)
          }}
        />
      </Box>
      <Box alignItems="center">
        <Button
          backgroundColor="accent"
          color="text"
          borderWidth="2px"
          onClick={createSaveFileClicked}
        >
          Save to File
        </Button>
        {error && (
          <Text color="reject" size="1.6rem">
            {error}
          </Text>
        )}
        {success && (
          <Text size="1.6rem" style={{ fontStyle: "italic" }}>
            {success}
          </Text>
        )}
      </Box>
    </InputsBox>
  )
}

export default BeforeSaveFile
