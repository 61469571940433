import React from "react"
import { Box, IconButton } from "../commonElements"
import { Times } from "@styled-icons/fa-solid"

import { useDispatch } from "react-redux"
import { openFileModal } from "../../redux/modal/actions"

import Backdrop from "./Backdrop"
import SaveFile from "../saveFile"

const FileModal = () => {
  const dispatch = useDispatch()

  return (
    <Backdrop>
      <Box
        maxWidth="45rem"
        width="95%"
        height="25rem"
        backgroundColor="white"
        alignItems="center"
        borderRadius="1rem"
        positionBox="relative"
        padding="3rem"
      >
        <Box positionBox="absolute" rightP=".7rem" topP=".7rem">
          <IconButton
            icon={<Times size={21} />}
            onClick={() => {
              dispatch(openFileModal(null))
            }}
          />
        </Box>
        <SaveFile />
      </Box>
    </Backdrop>
  )
}
export default FileModal
