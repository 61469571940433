import React from "react"
import { useSelector } from "react-redux"
import withLocation from "../HOC/withLocation"
import BeforeSaveFile from "./beforeSaveFile"
import BeforeDeleteFile from "./beforeDeleteFile"
import BeforeReplaceFile from "./beforeReplaceFile"
import BeforeOpenFile from "./beforeOpenFile"
import { Box, Heading } from "../commonElements"

const capitalizeFirstWord = inputString => {
  if (inputString.startsWith("/")) {
    inputString = inputString.slice(1)
  }
  const parts = inputString.split("-")
  if (parts.length >= 1) {
    const firstWord = parts[0].charAt(0).toUpperCase() + parts[0].slice(1)
    return firstWord
  }
  return inputString
}

const SaveFile = props => {
  let toolName = "Coin"
  const pathname = props.urlLocation.pathname
  const fileModalData = useSelector(state => state.modal.fileModalData)

  if (pathname.includes("dice")) {
    toolName = "Dice"
  } else if (pathname.includes("2048")) {
    toolName = "2048 Coin"
  } else if (pathname.includes("yes-or-no-tarot")) {
    toolName = "Tarot"
  } else if (pathname === "/intuition-test/") {
    toolName = "Intuition"
  } else if (pathname === "/luck-test/") {
    toolName = "Luck"
  } else if (pathname.includes("-cards")) {
    toolName = "Card"
  }

  const type =
    toolName === "Dice"
      ? pathname.split("/")[2] === ""
        ? "d6"
        : pathname.split("/")[2].replace("roll-", "")
      : toolName === "Card"
      ? pathname.split("/")[1].replace("-cards", "")
      : ""

  let headingTexts = ""
  if (fileModalData.mode === "New") {
    headingTexts = `Save ${
      toolName === "Card"
        ? `${capitalizeFirstWord(pathname)} Card`
        : toolName === "Dice" && type !== "d6"
        ? `${type.toUpperCase()} ${toolName}`
        : toolName
    } As...`
  } else {
    headingTexts = "Are You Sure?"
  }

  return (
    <Box alignItems="stretch" gap="2rem" alignSelf="stretch">
      <Box flexDirection="row" justifyContent="center" alignItems="center">
        <Heading mb="0">{headingTexts}</Heading>
        <Box width="22px"></Box>
      </Box>

      {fileModalData.mode === "New" && <BeforeSaveFile type={type} />}
      {fileModalData.mode === "Delete" && <BeforeDeleteFile />}
      {fileModalData.mode === "Replace" && <BeforeReplaceFile type={type} />}
      {fileModalData.mode === "Open" && <BeforeOpenFile />}
    </Box>
  )
}

export default withLocation(SaveFile)
