import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Text, Button, Loader } from "../commonElements"
import { setCurrentFileInfo, setDataStatus } from "../../redux/common/actions"
import { openFileModal } from "../../redux/modal/actions"
import { useAsyncDispatch } from "../../components/hook/useAsync"
import { getSaveFile } from "../../redux/saveFile/actions"

const BeforeOpenFile = () => {
  const [error, setError] = useState("")
  const dispatch = useDispatch()
  const fileModalData = useSelector(state => state.modal.fileModalData)
  const currentFileInfo = useSelector(state => state.common.currentFileInfo)

  const { execute: getSaveFileAsync, loading: getSaveFileLoading } =
    useAsyncDispatch(getSaveFile)

  const openFileClicked = async () => {
    setError("")
    try {
      const data = {
        sharePage: fileModalData.page,
        sharePath: fileModalData.path,
      }
      const result = await getSaveFileAsync(data)
      if (result.error) {
        setError(`Error. ${result.error}`)
      } else {
        dispatch(
          setCurrentFileInfo({ id: fileModalData.id, path: result.path })
        )
        dispatch(setDataStatus("Unchanged"))
        dispatch(openFileModal(null))
      }
    } catch (error) {
      setError(
        `Error. We can't open the file. Please try again later. If error persists, please let us know.`
      )
      console.log("error", error)
    }
  }

  if (getSaveFileLoading) return <Loader alignSelf="center" mt="40px" />

  return (
    <Box alignItems="center">
      <Text textAlign="center">
        {fileModalData.id === currentFileInfo.id
          ? `Are you sure you want to reopen File ${
              fileModalData.id + 1
            }? Current
        changes will be lost.`
          : `Are you sure you want to open File ${fileModalData.id + 1}? Unsaved
        changes in File ${currentFileInfo.id + 1} will be lost.`}
      </Text>
      <Box alignItems="center" mt="1.6rem">
        <Button onClick={openFileClicked}>Yes, noted.</Button>
        {error && (
          <Text color="reject" size="1.6rem">
            {error}
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default BeforeOpenFile
